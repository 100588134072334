



























import { getDefaultRouteByRole } from '@/router';

export default {
  methods: {
    goHome(): void {
      this.$router.push(getDefaultRouteByRole(this.$role.name));
    }
  }
};
